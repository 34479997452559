import React from 'react';
import routeFile from "../../routes";
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import tabActions from "../../actions/tabActions";
import userActions from "../../actions/userActions";
import backgroundLogo from "../../../src/assets/android-chrome-512x512.png";
import { FaEuroSign, FaExclamationTriangle, FaPhone } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import OtherUserModal from "../Modals/OtherUserModal";
import {textFilter} from "react-bootstrap-table2-filter";
import ItsBootstrapTableWithoutSelection from "../../Components/Tables/ItsBootstrapTableWithoutSelection";
import OpenPhoneNoteModal from "../Modals/OpenPhoneNoteModal";

class SideMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otherUserModal: false,
            openPhoneNoteModal: false
        };
    }

    componentDidMount() {
        const { users } = this.props;
        this.props.userActions.getTicketsSetByOtherUser(typeof users.loggedInUser.id !== 'undefined' ? users.loggedInUser.id : localStorage.user);
        // this.props.invoiceActions.getOpenInvoices(typeof users.loggedInUser.id !== 'undefined' ? users.loggedInUser.id : localStorage.user);
        this.props.userActions.getOpenPhoneNotes(typeof users.loggedInUser.id !== 'undefined' ? users.loggedInUser.id : localStorage.user);
    }

    addTab = (item) => {
        const { tabActions, tabs } = this.props;

        let addTab = true;
        let tabIdToSelect = 0;
        if (item.name.toLowerCase() === 'meldingen' || item.name.toLowerCase() === 'telefoonnotities') {
            Object.keys(tabs.byId).map( key => {
                if (item.name.toLowerCase() === tabs.byId[key].name.toLowerCase()) {
                    addTab = false;
                    tabIdToSelect = key;
                }
                return key;
            });
        }

        if (addTab) {
            tabActions.addTab(item)
        } else {
            tabActions.unselectTab(tabs.selected);
            tabActions.selectTab(tabIdToSelect);
        }
    };

    renderMenu = (routes) => {
        let prevCategories = [];

        return routes.map((category, index) => {
            if (index === 0 || !category.show) {
                return (null)
            }
            if (prevCategories.includes(category.category)) {
                return (null)
            }
            prevCategories.push(category.category);

            return (
                <div key={index}>
                    <h5 className={'menu-category'}>{category.category}</h5>
                    {routes.map((item, index) => {
                        return item.category === category.category && item.show ? (
                                <Link
                                    key={index}
                                    to={item.path}
                                    className={'menu-subcategory'}
                                    onClick={() => this.addTab(item)}
                                >
                                    <Nav.Item key={index}>
                                        {item.name}
                                    </Nav.Item>
                                </Link>
                        ) : (null)
                    })}
                </div>
            )
        })
    };

    openModal = (type) => {
        switch (type) {
            case 'otherUser':
                this.setState({
                    otherUserModal: true
                });
                break;
            case 'openInvoices':
                //Todo: Reroute to the open invoices page
                // const { tabActions } = this.props;
                // tabActions.addTab(routeFile[4], row);
                break;
            case 'openPhoneNotes':
                this.setState({
                    openPhoneNoteModal: true
                });
                break;
            default:
                return;
        }
    };

    handleClose = (modalName) => {
        this.setState({
            [modalName]: false
        })
    };

    getOtherUserData = () => {
        const { ticketsSetByOtherUser } = this.props.users.loggedInUser;
        return typeof ticketsSetByOtherUser !== 'undefined' ? ticketsSetByOtherUser : []
    };

    getOpenPhoneNoteData = () => {
        const { openPhoneNotes } = this.props.users.loggedInUser;
        return typeof openPhoneNotes !== 'undefined' ? openPhoneNotes : []
    };

    render() {
        const { otherUserModal, openPhoneNoteModal } = this.state;
        const { routes } = this.props;
        const { ticketsSetByOtherUser, openPhoneNotes } = this.props.users.loggedInUser;

        return (
            <>
                <Nav defaultActiveKey="/home" className="flex-column navbar-dark bg-dark side-menu">
                    <Navbar.Brand href="/">
                        <img
                            alt="MADDI"
                            src={backgroundLogo}
                            className="d-inline-block align-top navbarLogo"
                        />
                    </Navbar.Brand>

                    {this.renderMenu(routes)}

                    <div style={{position: 'absolute', bottom: '0', backgroundColor: 'var(--backgroundColor)', width: '98%', height: '30px', left: '1px'}}>
                        <Row style={{height: '100%'}}>
                            <Col sm={4} style={{padding: '0', fontSize: '20px'}}>
                                <div style={{width: '100%', margin: '-3px auto', height: '24px'}}>
                                    <OverlayTrigger
                                        key={'top'}
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Tickets die, door een andere medewerker, op jouw naam zijn gezet
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            onClick={() => this.openModal('otherUser')}
                                            style={{backgroundColor: 'inherit', height: '100%', color: 'black', width: '100%', border: 'none'}}
                                            disabled={typeof ticketsSetByOtherUser !== 'undefined' ? (ticketsSetByOtherUser.length === 0) : true}
                                        >
                                            <FaExclamationTriangle />
                                            <Badge pill variant="danger">{typeof ticketsSetByOtherUser !== "undefined" ? ticketsSetByOtherUser.length : 0}</Badge>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </Col>

                            <Col sm={4} style={{padding: '0', fontSize: '20px'}}>
                                <div style={{width: '100%', margin: '-3px auto', height: '24px'}}>
                                    <OverlayTrigger
                                        key={'top'}
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Openstaande factuurregels (Werkt momenteel niet)
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            onClick={() => this.openModal('openInvoices')}
                                            style={{backgroundColor: 'inherit', height: '100%', color: 'black', width: '100%', border: 'none'}}
                                            disabled={true}
                                        >
                                            <FaEuroSign/>
                                            <Badge pill variant="danger">0</Badge>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </Col>

                            <Col sm={4} style={{padding: '0', fontSize: '20px'}}>
                                <div style={{width: '100%', margin: '-3px auto', height: '24px'}}>
                                    <OverlayTrigger
                                        key={'top'}
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Openstaande persoonlijke telefoontjes
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            onClick={() => this.openModal('openPhoneNotes')}
                                            style={{backgroundColor: 'inherit', height: '100%', color: 'black', width: '100%', border: 'none'}}
                                            disabled={typeof openPhoneNotes !== 'undefined' ? (openPhoneNotes.length === 0) : true}
                                        >
                                            <FaPhone/>
                                            <Badge pill variant="danger">{typeof openPhoneNotes !== "undefined" ? openPhoneNotes.length : 0}</Badge>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Nav>

                {/*Modals*/}
                <OtherUserModal show={otherUserModal} handleClose={() => this.handleClose('otherUserModal')}>
                    { typeof ticketsSetByOtherUser === "undefined" ? (null) :
                        <ItsBootstrapTableWithoutSelection
                            keyField={'id'}
                            columns={[
                                {
                                    dataField: 'subGroup.name',
                                    text: '',
                                    filter: textFilter({placeholder: 'escalatie'}),
                                    headerStyle: {width: '6%'},
                                    sort: true
                                },
                                {
                                    dataField: 'id',
                                    text: '',
                                    filter: textFilter({placeholder: '#'}),
                                    headerStyle: {width: '4%'},
                                    sort: true
                                },
                                {
                                    dataField: 'date',
                                    text: '',
                                    filter: textFilter({placeholder: 'Datum aanmleding'}),
                                    headerStyle: {width: '8%'},
                                    sort: true
                                },
                                {
                                    dataField: 'changed',
                                    text: '',
                                    filter: textFilter({placeholder: 'Datum gewijzigd'}),
                                    headerStyle: {width: '8%'},
                                    sort: true
                                },
                                {
                                    dataField: 'customer.name',
                                    text: '',
                                    filter: textFilter({placeholder: 'Klant'}),
                                    headerStyle: {width: '10%'},
                                    sort: true
                                },
                                {
                                    dataField: 'contact.name',
                                    text: '',
                                    filter: textFilter({placeholder: 'Contact'}),
                                    headerStyle: {width: '10%'},
                                    sort: true
                                },
                                {
                                    dataField: 'userGroup.name',
                                    text: '',
                                    filter: textFilter({placeholder: 'Partner'}),
                                    headerStyle: {width: '6%'},
                                    sort: true
                                },
                                {
                                    dataField: 'description',
                                    text: '',
                                    filter: textFilter({placeholder: 'Titel'}),
                                    headerStyle: {width: '30%'},
                                    sort: true
                                },
                                {
                                    dataField: 'engineer.fullName',
                                    text: '',
                                    filter: textFilter({placeholder: 'Engineer'}),
                                    headerStyle: {width: '8%'},
                                    sort: true
                                },
                                {
                                    dataField: 'priority.name',
                                    text: '',
                                    filter: textFilter({placeholder: 'Prioriteit'}),
                                    headerStyle: {width: '4%'},
                                    sort: true
                                },
                                // {dataField: 'ticketType.name', text: '', filter: textFilter({placeholder: 'Facturatie type'}), style: (cell, row, rowIndex, colIndex) => styleTicketTypeColumn(cell, row, rowIndex, colIndex)},
                                {
                                    dataField: 'status.status',
                                    text: '',
                                    filter: textFilter({placeholder: 'Status'}),
                                    headerStyle: {width: '6%'},
                                    sort: true
                                },
                            ]}
                            rowClasses={ this.rowClasses }
                            data={this.getOtherUserData()}
                            rowEvents={{
                                onDoubleClick: (e, row) => {
                                    const { tabActions } = this.props;
                                    tabActions.addTab(routeFile[2], row);
                                }
                            }}
                        />
                    }
                </OtherUserModal>

                <OpenPhoneNoteModal show={openPhoneNoteModal} handleClose={() => this.handleClose('openPhoneNoteModal')}>
                    { typeof openPhoneNotes === "undefined" ? (null) :
                        <ItsBootstrapTableWithoutSelection
                            keyField={'id'}
                            columns={[
                                {
                                    dataField: 'subGroup.name',
                                    text: '',
                                    filter: textFilter({placeholder: 'escalatie'}),
                                    headerStyle: {width: '6%'},
                                    sort: true
                                },
                                {
                                    dataField: 'id',
                                    text: '',
                                    filter: textFilter({placeholder: '#'}),
                                    headerStyle: {width: '4%'},
                                    sort: true
                                },
                                {
                                    dataField: 'date',
                                    text: '',
                                    filter: textFilter({placeholder: 'Datum aanmleding'}),
                                    headerStyle: {width: '8%'},
                                    sort: true
                                },
                                {
                                    dataField: 'changed',
                                    text: '',
                                    filter: textFilter({placeholder: 'Datum gewijzigd'}),
                                    headerStyle: {width: '8%'},
                                    sort: true
                                },
                                {
                                    dataField: 'customer.name',
                                    text: '',
                                    filter: textFilter({placeholder: 'Klant'}),
                                    headerStyle: {width: '10%'},
                                    sort: true
                                },
                                {
                                    dataField: 'contact.name',
                                    text: '',
                                    filter: textFilter({placeholder: 'Contact'}),
                                    headerStyle: {width: '10%'},
                                    sort: true
                                },
                                {
                                    dataField: 'userGroup.name',
                                    text: '',
                                    filter: textFilter({placeholder: 'Partner'}),
                                    headerStyle: {width: '6%'},
                                    sort: true
                                },
                                {
                                    dataField: 'description',
                                    text: '',
                                    filter: textFilter({placeholder: 'Titel'}),
                                    headerStyle: {width: '30%'},
                                    sort: true
                                },
                                {
                                    dataField: 'engineer.fullName',
                                    text: '',
                                    filter: textFilter({placeholder: 'Engineer'}),
                                    headerStyle: {width: '8%'},
                                    sort: true
                                },
                                {
                                    dataField: 'priority.name',
                                    text: '',
                                    filter: textFilter({placeholder: 'Prioriteit'}),
                                    headerStyle: {width: '4%'},
                                    sort: true
                                },
                                // {dataField: 'ticketType.name', text: '', filter: textFilter({placeholder: 'Facturatie type'}), style: (cell, row, rowIndex, colIndex) => styleTicketTypeColumn(cell, row, rowIndex, colIndex)},
                                {
                                    dataField: 'status.status',
                                    text: '',
                                    filter: textFilter({placeholder: 'Status'}),
                                    headerStyle: {width: '6%'},
                                    sort: true
                                },
                            ]}
                            rowClasses={ this.rowClasses }
                            data={this.getOpenPhoneNoteData()}
                            rowEvents={{
                                onDoubleClick: (e, row) => {
                                    const { tabActions } = this.props;
                                    tabActions.addTab(routeFile[4], row);
                                }
                            }}
                        />
                    }
                </OpenPhoneNoteModal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabs: state.tabs,
        users: state.users
    }
};

const mapDispatchToProps = dispatch => {
    return {
        tabActions: bindActionCreators(tabActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
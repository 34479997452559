const actionTypes = {
    authentication: {
        LOGIN_SUCCESS: "LOGIN_SUCCESS",
        LOGIN_FAILED: "LOGIN_FAILED",
        LOGOUT: 'LOGOUT'
    },
    customers: {
        GET_CUSTOMERS_LIST_SUCCESS: "GET_CUSTOMERS_LIST_SUCCESS",
        GET_CUSTOMERS_LIST_FAILED: "GET_CUSTOMERS_LIST_FAILED",
        GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
        GET_CUSTOMERS_FAILED: "GET_CUSTOMERS_FAILED",
        getCustomerSuccess: "GET_CUSTOMER_SUCCESS",
        getCustomerFailed: "GET_CUSTOMER_FAILED",

        CUSTOMER_SELECTED: "CUSTOMER_SELECTED",
    },
    users: {
        GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
        GET_USERS_FAILED: "GET_USERS_FAILED",

        GET_USER_SUCCESS: "GET_USER_SUCCESS",
        GET_USER_FAILED: "GET_USER_FAILED",

        GET_LOGGEDIN_USER_SUCCESS: "GET_LOGGEDIN_USER_SUCCESS",
        GET_LOGGEDIN_USER_FAILED: "GET_LOGGEDIN_USER_FAILED",

        GET_TICKETS_SET_BY_OTHER_USER_SUCCESS: "GET_TICKETS_SET_BY_OTHER_USER_SUCCESS",
        GET_TICKETS_SET_BY_OTHER_USER_FAILED: "GET_TICKETS_SET_BY_OTHER_USER_FAILED",

        GET_OPEN_PHONENOTES_SUCCESS: "GET_OPEN_PHONENOTES_SUCCESS",
        GET_OPEN_PHONENOTES_FAILED: "GET_OPEN_PHONENOTES_FAILED",
    },
    tickets: {
        POST_TICKETS_SUCCESS: "POST_TICKETS_SUCCESS",
        POST_TICKETS_FAILED: "POST_TICKETS_FAILED",

        DELETE_TICKETS_SUCCESS: "DELETE_TICKETS_SUCCESS",
        DELETE_TICKETS_FAILED: "DELETE_TICKETS_FAILED",

        GET_TICKETS_SUCCESS: "GET_TICKETS_SUCCESS",
        GET_TICKETS_FAILED: "GET_TICKETS_FAILED",

        GET_TICKET_SUCCESS: "GET_TICKET_SUCCESS",
        GET_TICKET_FAILED: "GET_TICKET_FAILED",

        EDIT_TICKET_SUCCESS: "EDIT_TICKET_SUCCESS",
        EDIT_TICKET_FAILED: "EDIT_TICKET_FAILED",

        NEW_TICKET: "NEW_TICKET",

        RESET_TICKET_FORM: "RESET_TICKET_FORM",

        ADD_AUDIT: "ADD_AUDIT",

        CREATE_TASKS_SUCCESS: "CREATE_TASKS_SUCCESS",
        CREATE_TASKS_FAILED: "CREATE_TASKS_FAILED",

        ADD_TASK_SUCCESS: "ADD_TASK_SUCCESS",
        CHECK_TASK: "CHECK_TASK",

        REMOVE_TASK_SUCCESS: "REMOVE_TASK_SUCCESS",

        GET_CONTACT_HISTORY: "GET_CONTACT_HISTORY",
        GET_CUSTOMER_HISTORY: "GET_CUSTOMER_HISTORY",
        GET_CUSTOMER_DOSSIERS: "GET_CUSTOMER_DOSSIERS",

        GET_PREDEFINED_DATA: "GET_PREDEFINED_DATA",

        GET_TODOS_SUCCESS: "GET_TODOS_SUCCESS",
        GET_TODOS_FAILED: "GET_TODOS_FAILED"
    },
    engineers: {
        GET_ENGINEERS_LIST_SUCCESS: "GET_ENGINEERS_LIST_SUCCESS",
        GET_ENGINEERS_LIST_FAILED: "GET_ENGINEERS_LIST_FAILED",
    },
    ticketType: {
        GET_TICKETTYPE_LIST_SUCCESS: "GET_TICKETTYPE_LIST_SUCCESS",
        GET_TICKETTYPE_LIST_FAILED: "GET_TICKETTYPE_LIST_FAILED",

        GET_TICKETTYPES: "GET_TICKETTYPES"
    },

    contact: {
        POST_CONTACT: 'POST_CONTACT',
        POST_CONTACT_FAILED: 'POST_CONTACT_FAILED',

        CONTACT_SELECTED: 'CONTACT_SELECTED',
    },
    optionsList: {
        GET_TICKETOPTIONLISTS_SUCCESS: "GET_TICKETOPTIONLISTS_SUCCESS",

        GET_ENGINEERS_LIST_SUCCESS: 'GET_ENGINEERS_LIST_SUCCESS',
        GET_ENGINEERS_LIST_FAILED: 'GET_ENGINEERS_LIST_FAILED',

        GET_CUSTOMERS_LIST_SUCCESS: "GET_CUSTOMERS_LIST_SUCCESS",
        GET_CUSTOMERS_LIST_FAILED: "GET_CUSTOMERS_LIST_FAILED",

        GET_CONTACT_BY_CUSTOMER_LIST_SUCCESS: 'GET_CONTACT_BY_CUSTOMER_LIST_SUCCESS',
        GET_CONTACT_BY_CUSTOMER_LIST_FAILED: 'GET_CONTACT_BY_CUSTOMER_LIST_FAILED',

        GET_CATEGORY_LIST_SUCCESS: 'GET_CATEGORY_LIST_SUCCESS',
        GET_CATEGORY_LIST_FAILED: 'GET_CATEGORY_LIST_FAILED',

        GET_SUBCATEGORY_LIST_SUCCESS: 'GET_SUBCATEGORY_LIST_SUCCESS',
        GET_SUBCATEGORY_LIST_FAILED: 'GET_SUBCATEGORY_LIST_FAILED',

        GET_INCOMING_KIND_LIST_SUCCESS: 'GET_INCOMING_KIND_LIST_SUCCESS',
        GET_INCOMING_KIND_LIST_FAILED: 'GET_INCOMING_KIND_LIST_FAILED',

        GET_MESSAGE_KIND_LIST_SUCCESS: 'GET_MESSAGE_KIND_LIST_SUCCESS',
        GET_MESSAGE_KIND_LIST_FAILED: 'GET_MESSAGE_KIND_LIST_FAILED',

        GET_STATUS_LIST_SUCCESS: 'GET_STATUS_LIST_SUCCESS',
        GET_STATUS_LIST_FAILED: 'GET_STATUS_LIST_FAILED',

        GET_KNOWLEDGE_LIST_SUCCESS: 'GET_KNOWLEDGE_LIST_SUCCESS',
        GET_KNOWLEDGE_LIST_FAILED: 'GET_KNOWLEDGE_LIST_FAILED',

        GET_TEMPLATE_LIST_SUCCESS: 'GET_TEMPLATE_LIST_SUCCESS',
        GET_TEMPLATE_LIST_FAILED: 'GET_TEMPLATE_LIST_FAILED',

        GET_GROUP_LIST_SUCCESS: 'GET_GROUP_LIST_SUCCESS',
        GET_GROUP_LIST_FAILED: 'GET_GROUP_LIST_FAILED',

        GET_LEDGERS_LIST_SUCCESS: 'GET_LEDGERS_LIST_SUCCESS',
        GET_LEDGERS_LIST_FAILED: 'GET_LEDGERS_LIST_FAILED',

        GET_TICKETTYPE_LIST_SUCCESS: "GET_TICKETTYPE_LIST_SUCCESS",
        GET_TICKETTYPE_LIST_FAILED: "GET_TICKETTYPE_LIST_FAILED",

        GET_PRIORITY_LIST_SUCCESS: "GET_PRIORITY_LIST_SUCCESS",
        GET_PRIORITY_LIST_FAILED: "GET_PRIORITY_LIST_FAILED",

        GET_PRODUCT_LIST_SUCCESS: "GET_PRODUCT_LIST_SUCCESS",
        GET_PRODUCT_LIST_FAILED: "GET_PRODUCT_LIST_FAILED",

        GET_SUBGROUP_LIST_SUCCESS: "GET_SUBGROUP_LIST_SUCCESS",
        GET_SUPPLIER_LIST_SUCCESS: "GET_SUPPLIER_LIST_SUCCESS",
    },
    knowledge: {
        GET_KNOWLEDGE_SUCCESS: "GET_KNOWLEDGE_SUCCESS",
        SAVE_KNOWLEDGE_SUCCESS: "POST_KNOWLEDGE_SUCCESS",
        ADD_TASK_SUCCESS: "ADD_TASK_SUCCESS",
    },
    hours: {
        GET_DAILY_HOURS_SUCCESS: "GET_DAILY_HOURS_SUCCESS",
        GET_DAILY_HOURS_FAILED: "GET_DAILY_HOURS_FAILED",
    },
    tabs: {
        ADD_TAB: "ADD_TAB",
        REMOVE_TAB: "REMOVE_TAB",
        SELECT_TAB: "SELECT_TAB",
        ADD_TAB_FAILED: "ADD_TAB_FAILED",
        UNSELECT_TAB: "UNSELECT_TAB",
        RESET_ENTITY: "RESET_ENTITY",
        UPDATE_TAB_ENTITY_SUCCESS: "UPDATE_TAB_ENTITY_SUCCESS",
        SET_TAB_REMOVABLE_SUCCESS: "SET_TAB_REMOVABLE_SUCCESS"
    },
    phoneNotes: {
        GET_PHONENOTES_SUCCESS: "GET_PHONENOTES_SUCCESS",
        GET_PHONENOTE_SUCCESS: "GET_PHONENOTE_SUCCESS",

        POST_PHONENOTE_SUCCESS: "POST_PHONENOTE_SUCCESS",
        POST_PHONENOTE_FAILED: "POST_PHONENOTE_FAILED",

        PUT_PHONENOTE_SUCCESS: "PUT_PHONENOTE_SUCCESS",
        PUT_PHONENOTE_FAILED: "PUT_PHONENOTE_FAILED",

        GET_PHONENOTES_BY_CUSTOMER: "GET_PHONENOTES_BY_CUSTOMER"
    },
    ticketTasks: {
        GET_TICKETTASKS_SUCCESS: "GET_TICKETTASKS_SUCCESS"
    },
    groups: {
        GET_GROUPS_SUCCESS: "GET_GROUPS_SUCCESS"
    },
    filters: {
        GET_PERSONAL_FILTERS_SUCCESS: "GET_PERSONAL_FILTERS_SUCCESS",

        UPDATE_PERSONAL_FILTER_SUCCESS: "UPDATE_PERSONAL_FILTER_SUCCESS"
    },
    email: {
        SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS"
    }
};


export {
    actionTypes
}
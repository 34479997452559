import React from 'react';
import {Formik} from "formik";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import authenticationActions from '../../actions/authenticationActions';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Logo from '../../../src/assets/android-chrome-192x192.png';

class Login extends React.Component {

    render() {
        return (
            <div className="App">
                <Container style={{marginTop: '125px'}}>

                    <Row>
                        <Col xs={{span: 6, offset: 3}}>
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-center">
                                        <img alt="MADDI" src={Logo}/>
                                    </Card.Title>

                                    <Formik
                                        initialValues={{username: '', password: ''}}
                                        onSubmit={(values, {setSubmitting}) => {
                                            this.props.authenticationActions.loginCheck(values);
                                        }}
                                    >
                                        {({
                                              values,
                                              handleChange,
                                              handleBlur,
                                              handleSubmit,
                                          }) => (
                                            <Form onSubmit={handleSubmit} className="login-form">
                                                <Form.Group>
                                                    <Form.Label>Gebruikersnaam</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="username"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.username}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Wachtwoord</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        name="password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.password}

                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Button type="primary" className="login-form-button float-right btn-lg">
                                                        login
                                                    </Button>
                                                </Form.Group>
                                            </Form>
                                        )}
                                    </Formik>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({authentication}) => {
    return {
        authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        authenticationActions: bindActionCreators(authenticationActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React from 'react';
import Loadable from 'react-loadable'
import { FaExclamationTriangle, FaHome, FaListUl, FaPhone, FaTicketAlt} from "react-icons/fa";

import TicketPlusIcon from './assets/ticket-alt-solid_plus.png';
import TicketMultipleIcon from './assets/ticket-alt-solid_multiple.png';

function Loading() {
    return <div>Laden...</div>;
}

const Home = Loadable({
    loader: () => import('./containers/Layout/Home'),
    loading: Loading,
});

const EditTicket = Loadable({
    loader: () => import('./containers/Tickets/EditTicket'),
    loading: Loading,
});

const Notifications = Loadable({
    loader: () => import('./containers/Notifications/Notifications'),
    loading: Loading,
});

const PhoneNotes = Loadable({
    loader: () => import('./containers/PhoneNotes/PhoneNotes'),
    loading: Loading,
});

const EditPhoneNote = Loadable({
    loader: () => import('./containers/PhoneNotes/EditPhoneNote'),
    loading: Loading,
});

// const Relaties = Loadable({
//     loader: () => import('./containers/Todo/Relations/Relations'),
//     loading: Loading,
// });
//
// const ProductsAndSubscriptions = Loadable({
//     loader: () => import('./containers/Todo/ProductsAndSubscriptions/ProductsAndSubscriptions'),
//     loading: Loading,
// });
//
// const Domains = Loadable({
//     loader: () => import('./containers/Todo/Domains/Domains'),
//     loading: Loading,
// });
//
// const Kennis = Loadable({
//     loader: () => import('./containers/Todo/Knowledge/Knowledge'),
//     loading: Loading,
// });
//
// const Instellingen = Loadable({
//     loader: () => import('./containers/Settings/Settings'),
//     loading: Loading,
// });
//
// const Gebruikers = Loadable({
//     loader: () => import('./containers/Users/Users'),
//     loading: Loading,
// });
//
// const Integrations = Loadable({
//     loader: () => import('./containers/Todo/Integrations/Integrations'),
//     loading: Loading,
// });
//
// const Procedures = Loadable({
//     loader: () => import('./containers/Todo/Procedures/Procedures'),
//     loading: Loading,
// });
//
// const Facturatie = Loadable({
//     loader: () => import('./containers/Billing/Facturatie'),
//     loading: Loading,
// });
//
// const NewIntegration = Loadable({
//     loader: () => import('./containers/Todo/Integrations/NewIntegration'),
//     loading: Loading,
// });
//
// const EditStandardSubscription = Loadable({
//     loader: () => import('./containers/Todo/ProductsAndSubscriptions/EditStandardSubscriptions'),
//     loading: Loading,
// });
//
// const EditSubscription = Loadable({
//     loader: () => import('./containers/Todo/ProductsAndSubscriptions/EditSubscriptions'),
//     loading: Loading,
// });
//
// const EditProduct = Loadable({
//     loader: () => import('./containers/Todo/ProductsAndSubscriptions/EditProducts'),
//     loading: Loading,
// });
//
// const EditDomains = Loadable({
//     loader: () => import('./containers/Todo/Domains/EditDomains'),
//     loading: Loading,
// });
//
// const EditSystemUser = Loadable({
//     loader: () => import('./containers/Users/EditSystemUser'),
//     loading: Loading,
// });
//
// const EditCustomerUser = Loadable({
//     loader: () => import('./containers/Users/EditCustomerUser'),
//     loading: Loading,
// });

const routes = [
    {
        path: '/home',
        exact: true,
        name: 'Home',
        component: Home,
        active: true,
        entity: {
            id: null,
            name: ''
        },
        category: '',
        show: false,
        icon: <FaHome className={'home-tab-icon'} />,
        isRemovable: false
    }, {
        path: '/notificaties',
        exact: true,
        name: 'Meldingen',
        component: Notifications,
        active: false,
        entity: {
            id: null,
            name: ''
        },
        category: 'Uren',
        show: true,
        icon : <img className={'tab-icon'} src={TicketMultipleIcon} alt={'tickets-icon'} />,
        isRemovable: true
    }, {
        path: '/nieuw',
        exact: true,
        name: 'Melding',
        component: EditTicket,
        active: false,
        entity: {
            id: null,
            name: 'tickets'
        },
        category: 'Uren',
        show: true,
        icon: <img className={'tab-icon'} src={TicketPlusIcon} alt={'ticket-plus-icon'} />,
        isRemovable: true
    }, {
        path: '/telefoonnotities',
        exact: true,
        name: 'Telefoonnotities',
        component: PhoneNotes,
        active: false,
        entity: {
            id: null,
            name: ''
        },
        category: 'Uren',
        show: true,
        icon: <FaListUl className={'tab-icon'} />,
        isRemovable: true
    },
    {
        path: '/telefoonnotitie',
        exact: true,
        name: 'Tel-notitie',
        component: EditPhoneNote,
        active: false,
        entity: {
            id: null,
            name: ''
        },
        category: 'Uren',
        show: false,
        icon: <FaPhone className={'tab-icon'} />,
        isRemovable: true
    },
    // {
    //     path: '/relaties',
    //     exact: true,
    //     name: 'Relatie',
    //     component: Relaties,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: 'Klanten',
    //     show: true
    // }, {
    //     path: '/producten_abonnementen',
    //     exact: true,
    //     name: 'Producten En Abonnementen',
    //     component: ProductsAndSubscriptions,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: 'Producten',
    //     show: true
    // }, {
    //     path: '/domeinen',
    //     exact: true,
    //     name: 'Domains',
    //     component: Domains,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: 'Producten',
    //     show: true
    // }, {
    //     path: '/kennis',
    //     exact: true,
    //     name: 'Kennis',
    //     component: Kennis,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: 'Account',
    //     show: true
    // }, {
    //     path: '/instellingen',
    //     exact: true,
    //     name: 'Instellingen',
    //     component: Instellingen,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: 'Account',
    //     show: true
    // }, {
    //     path: '/gebruikers',
    //     exact: true,
    //     name: 'Gebruikers',
    //     component: Gebruikers,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: 'Account',
    //     show: true
    // }, {
    //     path: '/integrations',
    //     exact: true,
    //     name: 'Integrations',
    //     component: Integrations,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: 'Account',
    //     show: true
    // }, {
    //     path: '/procedures',
    //     exact: true,
    //     name: 'Procedures',
    //     component: Procedures,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: 'Account',
    //     show: false
    // }, {
    //     path: '/facturatie',
    //     exact: true,
    //     name: 'Facturaties',
    //     component: Facturatie,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: 'Facturatie',
    //     show: true
    // }, {
    //     path: '/newIntegration',
    //     exact: true,
    //     name: 'Niewe Integratie',
    //     component: NewIntegration,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: '',
    //     show: false
    // }, {
    //     path: '/nieuwStandaardAbonnement',
    //     exact: true,
    //     name: 'Nieuw Standaard Abonnement',
    //     component: EditStandardSubscription,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: '',
    //     show: false
    // }, {
    //     path: '/nieuwAbonnement',
    //     exact: true,
    //     name: 'Nieuw Abonnement',
    //     component: EditSubscription,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: '',
    //     show: false
    // }, {
    //     path: '/nieuwProduct',
    //     exact: true,
    //     name: 'Nieuw Product',
    //     component: EditProduct,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: '',
    //     show: false
    // }, {
    //     path: '/bewerkDomeinen',
    //     exact: true,
    //     name: 'Bewerk Domeinen',
    //     component: EditDomains,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: '',
    //     show: false
    // }, {
    //     path: '/bewerkSysteemGebruiker',
    //     exact: true,
    //     name: 'Bewerk Systeemgebruiker',
    //     component: EditSystemUser,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: '',
    //     show: false
    // }, {
    //     path: '/bewerkKlantenGebruiker',
    //     exact: true,
    //     name: 'Bewerk Klantengebruiker',
    //     component: EditCustomerUser,
    //     active: false,
    //     entity: {
    //         id: null,
    //         name: ''
    //     },
    //     category: '',
    //     show: false
    // },
];

export default routes;

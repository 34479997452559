import {apiRoutes} from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";
import { actionTypes } from "../constants/actionTypes";

const getTicketTypes = (queryString = "") => {
    return async dispatch => {
        try {
            let url = apiRoutes.ticketType.getTicketTypes + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.ticketType.GET_TICKETTYPES,
                payload: response
            });

        } catch (error) {
            console.log(error);
        }
    }
};

export default {
    getTicketTypes
};
import {apiRoutes} from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";
import { actionTypes } from "../constants/actionTypes";

const { optionsList } = actionTypes;

const getTicketOptionLists = (queryString) => {
    return async dispatch => {
        try {
            let url = apiRoutes.optionsList.getTicketOptionLists + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_TICKETOPTIONLISTS_SUCCESS,
                payload: response
            })

        } catch (error) {
            console.log(error);
        }
    }
};

const getEngineerList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getEngineerList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: optionsList.GET_ENGINEERS_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: optionsList.GET_ENGINEERS_LIST_FAILED });
        }
    }
};

const getCustomerList = (queryString) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getCustomerList + queryString;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: optionsList.GET_CUSTOMERS_LIST_SUCCESS,
                payload: response,
            });

        } catch (error) {
            dispatch({ type: optionsList.GET_CUSTOMERS_LIST_FAILED });
        }
    }
};

const getContactByCustomerList = (customerid) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getContactByCustomerList + "/" + customerid,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: optionsList.GET_CONTACT_BY_CUSTOMER_LIST_SUCCESS,
                payload: response,
            });

        } catch (error) {
            dispatch({ type: optionsList.GET_CONTACT_BY_CUSTOMER_LIST_FAILED });
        }
    }
};

const getCategoryList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getCategoryList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
                // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: optionsList.GET_CATEGORY_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: optionsList.GET_CATEGORY_LIST_FAILED });
        }
    }
};

const getSubcategoryList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getSubcategoryList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
                // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: optionsList.GET_SUBCATEGORY_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: optionsList.GET_SUBCATEGORY_LIST_FAILED });
        }
    }
};

const getGroupList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getGroupList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: optionsList.GET_GROUP_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: optionsList.GET_GROUP_LIST_FAILED });
        }
    }
};

const getStatusList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getStatusList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
            // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_STATUS_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: actionTypes.optionsList.GET_STATUS_LIST_FAILED });
        }
    }
};

const getPriorityList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getPriorityList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
                // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_PRIORITY_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: actionTypes.optionsList.GET_PRIORITY_LIST_FAILED });
        }
    }
};

const getIncomingKindList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getIncomingKindList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
                // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_INCOMING_KIND_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: actionTypes.optionsList.GET_INCOMING_KIND_LIST_FAILED });
        }
    }
};

const getMessageKindList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getMessageKindList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
                // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_MESSAGE_KIND_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: actionTypes.optionsList.GET_MESSAGE_KIND_LIST_FAILED });
        }
    }
};

const getTicketTypeList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getTicketTypeList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
                // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_TICKETTYPE_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: actionTypes.optionsList.GET_TICKETTYPE_LIST_FAILED });
        }
    }
};

const getKnowledgeList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getKnowledgeList+ queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
                // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_KNOWLEDGE_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: actionTypes.optionsList.GET_KNOWLEDGE_LIST_FAILED });
        }
    }
};

const getTemplateList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getTemplateList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
                // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_TEMPLATE_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: actionTypes.optionsList.GET_TEMPLATE_LIST_FAILED });
        }
    }
};

const getLedgerList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getLedgerList+ queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
            // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_LEDGERS_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: actionTypes.optionsList.GET_LEDGERS_LIST_FAILED });
        }
    }
};

const getProductList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.optionsList.getProductList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
                // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_PRODUCT_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            dispatch({ type: actionTypes.optionsList.GET_PRODUCT_LIST_FAILED });
        }
    }
};

const getSubGroupList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try{
            let url = apiRoutes.optionsList.getSubGroupList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);
                // return await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_SUBGROUP_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            console.log(error);
        }
    }
};

const getSupplierList = (queryString, userGroupId) => {
    return async (dispatch) => {
        try{
            let url = apiRoutes.optionsList.getCustomerList + queryString,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.optionsList.GET_SUPPLIER_LIST_SUCCESS,
                payload: {data: response, userGroupId: userGroupId},
            });

        } catch (error) {
            console.log(error);
        }
    }
};

export default {
    getTicketOptionLists,
    getEngineerList,
    getCustomerList,
    getContactByCustomerList,
    getCategoryList,
    getSubcategoryList,
    getStatusList,
    getPriorityList,
    getIncomingKindList,
    getMessageKindList,
    getTicketTypeList,
    getKnowledgeList,
    getTemplateList,
    getGroupList,
    getLedgerList,
    getProductList,
    getSubGroupList,
    getSupplierList
}
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import routes from '../routes';
import tabActions from "../actions/tabActions";
import phoneNoteActions from "../actions/phoneNoteActions";
import { toast } from "react-toastify";

const mapStateToProps = state => {
    return {
        loggedInUser: state.users.loggedInUser,
        phoneNote: state.phoneNote,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        tabActions: bindActionCreators(tabActions, dispatch),
        phoneNoteActions: bindActionCreators(phoneNoteActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(function PhoneSystem(props) {
    // Create Node.js connection
    props.io.on('connect', () => {
        props.io.on('phone_update', (res) => {
            if (Notification.permission !== "granted") {
                Notification.requestPermission();
            } else {
                // Process the siganl if pop-up permission are set to allow
                processSignal(res);
            }
        });
    });

    const processSignal = (res) => {
        // Check if the response corresponds to the currently logged in user. Then display the pop-up
        const { loggedInUser } = props;
        // if ((res.phone_data.phone_id === '004' || res.phone_data.phone_id === '007') && res.phone_data.action == 'pickup') {
        if (loggedInUser.phoneId === res.phone_data.phone_id && res.phone_data.action.toLowerCase() === 'pickup') {
            const title = "Telefoontje";
            let body = "Klik hier om telefoonnotitie te openen";

            // No phoneNote was created
            if (res.server_data.telefoonnotitie_id === 0) {
                return;
            }

            // Get the name or phonenumber of the caller
            if (typeof res.server_data.caller_name !== 'undefined') {
                if (res.phone_data.call_direction === "in") {
                    body += '. Van ' + res.server_data.caller_name;
                } else if (res.phone_data.call_direction === "out") {
                    body += '. Naar ' + res.server_data.caller_name;
                }
            } else if (res.phone_data.caller_number !== "undefined") {
                if (res.phone_data.call_direction === "in") {
                    body += '. Van ' + res.phone_data.caller_number;
                } else if (res.phone_data.call_direction === "out") {
                    body += '. Naar ' + res.phone_data.caller_number;
                }
            }

            // let dateObject = new Date();
            // let date = dateObject.getTime();
            // var popupTag = "phone_pickup_" + date;
            let popupTag = "phone_" + res.phone_data.action + "_" + res.server_data.telefoonnotitie_id;

            const notification = new Notification(
                title,
                {
                    icon: '/img/Logo-IT-Synergy.png',
                    body: body,
                    requireInteraction: true,
                    tag: popupTag,
                },
            );

            // notification.onClick = () => openPhoneNote(res);
            notification.onclick = (e) => openPhoneNote(e, res);
        }
    };

    const openPhoneNote = (e, res) => {
        e.preventDefault();
        const { phoneNoteActions, tabActions } = props;

        phoneNoteActions.getPhoneNote(res.server_data.telefoonnotitie_id).then(res => {
            if (res.length > 0) {
                tabActions.addTab(routes[4], res);
            } else {
                toast.error('De betreffende teelfoonnotitie kon helaas niet worden geopend.', {position: 'bottom-right'});
            }
        });
    };

    return null;
});
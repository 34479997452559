import { actionTypes } from "../constants/actionTypes";
import {normalize, schema} from "normalizr";

const { users } = actionTypes;

const userSchema = new schema.Entity('users', {
    id: '',
    username: '',
    email: '',
    customerId: '',
    firstName: '',
    lastName: '',
    fullName: '',
    roles: [],
},{ idAttribute: 'id' });

const initialState = {
    loggedInUser: {
        groups: [],
        ticketsSetByOtherUser: [],
        openPhoneNotes: [],
    },
    users: normalize([], [userSchema]),
    list: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case users.GET_USERS_SUCCESS:
            return {
                ...state,
                users: normalize(action.payload, [ userSchema ]),
                list: action.payload,
            };
        case users.GET_LOGGEDIN_USER_SUCCESS:
            return {
                ...state,
                loggedInUser: action.payload,
            };
        case users.GET_TICKETS_SET_BY_OTHER_USER_SUCCESS:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    ticketsSetByOtherUser: action.payload
                }
            };
        case users.GET_OPEN_PHONENOTES_SUCCESS:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    openPhoneNotes: action.payload
                }
            };
        default:
            return state;
    }
}
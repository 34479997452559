import React from 'react';
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";

class ItsBootstrapTable extends React.Component {

    render() {
        const { columns, data, keyField, rowClasses, rowEvents } = this.props;

        return (
            <BootstrapTable
                keyField={ !!keyField ? keyField : 'id' }
                className={'filteredNotifications'}
                columns={ columns }
                filter={ filterFactory() }
                rowClasses={ rowClasses }
                data={ data }
                rowEvents={ rowEvents }
                hover
            />
        )
    }
}

export default ItsBootstrapTable;
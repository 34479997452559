import { actionTypes } from "../constants/actionTypes";

const { ticketTasks } = actionTypes;

const initialState = {
    ticketTasks: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case ticketTasks.GET_TICKETTASKS_SUCCESS:
            console.log(action.payload);
            return {
                ...state,
                ticketTasks: [action.payload]
            };
        default:
            return state;
    }
}
import { actionTypes } from "../constants/actionTypes";
import {normalize, schema} from "normalizr";
import * as moment from "moment";

const { phoneNotes } = actionTypes;

const engineer = new schema.Entity('engineers', {}, { idAttribute: 'id' });
const contact = new schema.Entity('contacts', {}, {idAttribute: 'id'});
const customer = new schema.Entity('customers', {
    contacts: [contact]
}, {idAttribute: 'id'});

const phoneNoteSchema = new schema.Entity('phoneNotes', {
    engineer: engineer,
    customer: customer,
},{ idAttribute: 'id' });

const phoneNoteFormData = {
    id: 0,
    note: '',
    date: new Date().toLocaleDateString(),
    phoneNumber: '',
    group: {},
    phoneStatus: {value: '', label: ''},
    engineer: {value: Number(localStorage.getItem('user')), label: localStorage.getItem('username')},
    customer: {value: null, label: null},
    contact: {},
    userGroup: {},
    customerDetails: {
        contactHistory: [],
        customerHistory: [],
    }
};

const initialState = {
    phoneNotes: normalize([], [phoneNoteSchema]),
    phoneNote: {},
    phoneNotesByCustomer: [],
    list: [],
    emptyPhoneNote: phoneNoteFormData,
};


export default (state = initialState, action) => {
    switch(action.type) {
        case phoneNotes.GET_PHONENOTES_SUCCESS:
            return {
                ...state,
                phoneNotes: normalize(action.payload.data, [phoneNoteSchema]),
                // [action.payload.name]: action.payload.data,

                [action.payload.name]: action.payload.data.map(phoneNote => {
                    phoneNote.date = moment(phoneNote.date).format('DD-MM-YYYY');


                    return phoneNote
                }),
            };
        case phoneNotes.GET_PHONENOTE_SUCCESS:
            return {
                ...state,
                phoneNote: action.payload.data
            };
        case phoneNotes.GET_PHONENOTES_BY_CUSTOMER:
            return {
                ...state,
                phoneNotesByCustomer: {
                    ...state.phoneNotesByCustomer,
                    [action.payload.customerId]: action.payload.data.map(item => {
                        item.from = moment(item.from).format('HH:mm');
                        item.till = moment(item.till).format('HH:mm');
                        return item;
                    })
                }
            };
        default:
            return state;
    }
}
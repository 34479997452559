import decode from 'jwt-decode';

export default class AuthenticationService {

    static getToken() {
        return localStorage.getItem('token');
    }

    static setToken(token) {
        return localStorage.setItem('token', token);
    }

    static loggedIn() {
        const token = localStorage.getItem('token');

        return !!token && !this.isTokenExpired(token);
    }

    static logout() {
        localStorage.clear();
    }

    static isTokenExpired(token) {
        try {
            const decoded = decode(token);

            return decoded.exp < Date.now() / 1000;
        } catch (error) {
            return false;
        }
    }

    static getUser() {
        return localStorage.getItem('user')
    }

    static setUser(user) {
        return localStorage.setItem('user', user)
    }
    static setUsername(user) {
        return localStorage.setItem('username', user.firstName + ' ' + user.lastName)
    }
    static setUsergroup(user) {
        return localStorage.setItem('usergroup', user.userGroupId)
    }

};
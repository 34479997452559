import { actionTypes } from "../constants/actionTypes";
import { normalize, schema } from 'normalizr';

const { ticketType } = actionTypes;

const ticketTypeSchema = new schema.Entity('ticketTypes', {},{ idAttribute: 'id' });

const emptyTicketTypeObject = {

};

const initialState = {
    ticketTypes: normalize([], [ticketTypeSchema]),
    emptyTicketType: emptyTicketTypeObject,
};

export default (state = initialState, action) => {
    switch(action.type) {
        case ticketType.GET_TICKETTYPES:
            return {
                ...state,
                ticketTypes: normalize(action.payload, [ticketTypeSchema])
            };
        default:
            return state;
    }
}
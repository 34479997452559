import { actionTypes } from "../constants/actionTypes";
import { normalize, schema } from 'normalizr';

const { customers } = actionTypes;

const contactSchema = new schema.Entity('contacts', {}, {idAttribute: 'id'});

const customerSchema = new schema.Entity('customers', {
    contacts: [contactSchema]
}, {
    idAttribute: 'id',
});

const initialState = {
    customers: normalize([], [customerSchema]),
    selected: null,
    contacts: [],
    list: [],
};

export default (state = initialState, action) => {
    switch(action.type) {
        case customers.GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: normalize(action.payload, [customerSchema]),
            };
        case customers.getCustomerSuccess:
            return {
                ...state,
                current: action.payload,
            };
        case customers.GET_CUSTOMERS_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload,
            };
        case customers.CUSTOMER_SELECTED:
            return {
                ...state,
                selected: action.payload
            };
        default:
            return state;
    }
}
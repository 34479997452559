import { actionTypes } from "../constants/actionTypes";
import AuthenticationService from '../services/authenticationService';

const { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } = actionTypes.authentication;

const initialState = {
    isLoggedIn: AuthenticationService.loggedIn(),
    loginError: false,
};

export default (state = initialState, action) => {
    switch(action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true
            };
        case LOGIN_FAILED:
            return {
                ...state,
                isLoggedIn: false
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false
            };
        default:
            return state;
    }
}

import React from 'react';
import Button from 'react-bootstrap/Button';

const ItsBtn = (props) => {
    return (
        <Button
            size={'sm'}
            variant={!!props.variant ? 'outline-dark' : props.variant}
            disabled={!!props.disabled}
            block={!!props.block}
            onClick={props.onClick}
            className={props.className}
        >
            {props.text}
        </Button>
    )
};

export default ItsBtn;
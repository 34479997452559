import { actionTypes } from "../constants/actionTypes";
import TicketIcon from '../assets/ticket-alt-solid_1.png';
import React from "react";

const { tabs } = actionTypes;

const addTab = (data, entity = null) => {
    // Check if data is an overview page, then don't open a new one
    let newData = {
        ...data,
    };
    if (entity !== null) {
        newData.entity = entity;
    }

    // Replace the icon with another icon
    if (typeof newData.entity.id === 'number' && newData.name === 'Melding') {
        newData.icon = <img className={'tab-icon'} src={TicketIcon} alt={'ticket-icon'} />;
    }

    return async (dispatch) => {
        try {
            dispatch({
                type: tabs.ADD_TAB,
                payload: newData,
            });

        } catch (error) {
            dispatch({ type: tabs.ADD_TAB_FAILED });
        }
    }
};

const removeTab = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: tabs.REMOVE_TAB,
                payload: data,
            });
        } catch (error) {
            console.log(error)
        }
    }
};

const selectTab = (data) => {
    return (dispatch) => {
        try {
            dispatch({
                type: tabs.SELECT_TAB,
                payload: data,
            });
        } catch (error) {

        }
    }
};

const unselectTab = tabId => {
    return (dispatch) => {
        try {
            dispatch({
                type: tabs.UNSELECT_TAB,
                payload: tabId,
            });
        } catch (error) {
            console.log(error)
        }
    }
};

const updateTabEntity = (tabId, entity) => {
    return dispatch => {
        try {
            dispatch({
                type: tabs.UPDATE_TAB_ENTITY_SUCCESS,
                payload: {tabId: tabId, entity: entity}
            });
        } catch (error) {
            console.log(error);
        }
    }
};

const setTabRemovable = (tabId, isRemovable) => {
    return dispatch => {
        try {
            dispatch({
                type: tabs.SET_TAB_REMOVABLE_SUCCESS,
                payload: {tabId: tabId, isRemovable: isRemovable}
            });
        } catch (error) {
            console.log(error);
        }
    }
};

export default {
    addTab,
    removeTab,
    selectTab,
    unselectTab,
    updateTabEntity,
    setTabRemovable
}
import * as moment from 'moment';
import { apiRoutes } from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";
import { actionTypes } from "../constants/actionTypes";

const getDailyHours = (userId = null) => {
    return async (dispatch) => {
        const today = moment().format('DD-MM-YYYY');

        try {
            let url = apiRoutes.hours.getDailyHours + '?date=' + today + '&userid=' + userId ,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.hours.GET_DAILY_HOURS_SUCCESS,
                payload: response
            });

        } catch {
            dispatch({type: actionTypes.hours.GET_DAILY_HOURS_FAILED});
        }
    }
};

export default {
    getDailyHours
};
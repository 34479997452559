import { actionTypes } from "../constants/actionTypes";
import {normalize, schema} from "normalizr";

const { groups } = actionTypes;

const groupSchema = new schema.Entity('groups', {
    id: '',
    type: '',
    userGroupId: '',
    ticketTypeId: '',
    userGroup: {},
    ticketType: {},
    members: ''
},{ idAttribute: 'id' });

const initialState = {
    groups: normalize([], [groupSchema]),
    list: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case groups.GET_GROUPS_SUCCESS:
            return {
                ...state,
                groups: normalize(action.payload, [ groupSchema ]),
                list: action.payload,
            };
        default:
            return state;
    }
}
import { actionTypes } from "../constants/actionTypes";
import { apiRoutes } from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";

const { users } = actionTypes;

const getLoggedInUser = id => {
    return async dispatch => {
        try {
            let url = apiRoutes.users.getUser + id,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: users.GET_LOGGEDIN_USER_SUCCESS,
                payload: response,
            });

        } catch (error) {
            dispatch({ type: users.GET_LOGGEDIN_USER_FAILED })
        }
    }
};

const getUser = id => {
    return async dispatch => {
        try {
            let url = apiRoutes.users.getUser + id,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: users.GET_USER_SUCCESS,
                payload: response,
            });

        } catch (error) {
            dispatch({ type: users.GET_USER_FAILED })
        }
    }
};

const getUsers = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.users.getUsers + '?limit=250',
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.users.GET_USERS_SUCCESS,
                payload: response,
            });
        } catch (error) {
            dispatch({ type: users.GET_USERS_FAILED })
        }
    }
};

const getTicketsSetByOtherUser = (userId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.tickets.getTickets + '?engineer_id=' + userId + "&on_other_user=1",
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.users.GET_TICKETS_SET_BY_OTHER_USER_SUCCESS,
                payload: response,
            });
        } catch (error) {
            dispatch({ type: users.GET_TICKETS_SET_BY_OTHER_USER_FAILED })
        }
    }
};

const getOpenPhoneNotes = (userId) => {
    return async dispatch => {
        try {
            let url = apiRoutes.phoneNotes.openPhoneNotes + "?open=1&engineer_id=" + userId;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.users.GET_OPEN_PHONENOTES_SUCCESS,
                payload: response
            })

        } catch(error) {
            dispatch({type: actionTypes.users.GET_OPEN_PHONENOTES_FAILED})
        }
    }
};

export default {
    getLoggedInUser,
    getUser,
    getUsers,
    getTicketsSetByOtherUser,
    getOpenPhoneNotes
}
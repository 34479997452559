import { actionTypes } from "../constants/actionTypes";
import { apiRoutes } from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";
import moment from 'moment';

const { phoneNotes } = actionTypes;

const getPhoneNotes = (queryString, name) => {
    return async dispatch => {
        try {
            let url = apiRoutes.phoneNotes.getPhoneNotes  + '?' + queryString;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: phoneNotes.GET_PHONENOTES_SUCCESS,
                payload: {name: name, data: response}
            });
        } catch (error) {
            console.log(error)
        }
    }
};

const getPhoneNotesByCustomer = (queryString, customerId) => {
    return async dispatch => {
        try {
            let url = apiRoutes.phoneNotes.getPhoneNotesByCustomer  + '?' + queryString;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: phoneNotes.GET_PHONENOTES_BY_CUSTOMER,
                payload: {customerId: customerId, data: response}
            });
        } catch (error) {
            console.log(error)
        }
    }
};

const getPhoneNote = (phoneNoteId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.phoneNotes.getPhoneNote + phoneNoteId;
            return await ApiService.performRequest(RequestType.GET, url);

            // dispatch({
            //     type: actionTypes.phoneNotes.GET_PHONENOTE_SUCCESS,
            //     payload: response,
            // });
        } catch (error) {
            dispatch({type: actionTypes.tickets.GET_TICKET_FAILED});
        }
    }
};

const postPhoneNote = (data) => {
    return async dispatch => {
        const postObject = convertDataForBackend(data);

        postObject.from = moment(postObject.from).format('YYYY-MM-DD HH:mm:ss');
        postObject.till = moment(postObject.till).format('YYYY-MM-DD HH:mm:ss');

        try {
            let url = apiRoutes.phoneNotes.postPhoneNote,
                response = await ApiService.performRequest(RequestType.POST, url, postObject);

            dispatch({
                type: actionTypes.phoneNotes.POST_PHONENOTE_SUCCESS,
                payload: response
            })
        } catch(error) {
            dispatch({type: actionTypes.phoneNotes.POST_PHONENOTE_FAILED})
        }
    }
};

const putPhoneNote = (data) => {
    return async dispatch => {
        const putObject = convertDataForBackend(data);
        
        putObject.from = moment(putObject.from).format('YYYY-MM-DD HH:mm:ss');
        putObject.till = moment(putObject.till).format('YYYY-MM-DD HH:mm:ss');

        try {
            let url = apiRoutes.phoneNotes.putPhoneNote + "/" + putObject.id;
                return await ApiService.performRequest(RequestType.PATCH, url, putObject);

        } catch(error) {
            dispatch({type: actionTypes.phoneNotes.PUT_PHONENOTE_FAILED})
        }
    }
};

const convertDataForBackend = (data) => {
    let returnObject = {};

    Object.keys(data).map((key) => {
        let input = data[key];

        if (typeof input.value !== 'undefined' && typeof input.value !== 'object') {
            returnObject = {
                ...returnObject,
                [key]: input.value
            };
        } else if (typeof input === 'object') {
            if (Array.isArray(input)) {
                let arrayOfObjects = [];

                input.map((value) => {
                    let arrayObject = {};

                    Object.keys(value).map((objectKey) => {
                        let objectInput = value[objectKey];

                        if (typeof objectInput.value !== 'undefined') {
                            arrayObject = {
                                ...arrayObject,
                                [objectKey]: objectInput.value
                            };
                        } else if (typeof objectInput.id !== 'undefined') {
                            arrayObject = {
                                ...arrayObject,
                                [objectKey]: objectInput.id
                            };
                        } else {
                            arrayObject = {
                                ...arrayObject,
                                [objectKey]: objectInput
                            };
                        }
                        return objectKey;
                    });
                    arrayOfObjects.push(arrayObject);

                    return value;
                });

                returnObject = {
                    ...returnObject,
                    [key]: arrayOfObjects
                };
            }
        } else {
            returnObject = {
                ...returnObject,
                [key]: input
            };
        }

        return input;
    });

    return returnObject;
};

export default {
    getPhoneNotes,
    getPhoneNote,
    postPhoneNote,
    putPhoneNote,
    getPhoneNotesByCustomer,
};
import { actionTypes } from "../constants/actionTypes";
import routes from '../routes';
import { toast } from "react-toastify";

routes[0].id = "0";

const { tabs } = actionTypes;

const initialState = {
    byId: {
        [routes[0].id]: routes[0]
    },
    allIds: [
        routes[0].id,
    ],
    selected: routes[0].id,
    routes: routes,
};

export default (state = initialState, action) => {
    switch(action.type) {
        case tabs.ADD_TAB:
            let datum = new Date(),
                newTabs = { ...state.byId };

            // Show popup if newTabs.length is already 9
            if (Object.keys(newTabs).length === 10) {
                toast('Sluit eerst een bestaand tabblad voordat je een nieuwe opent.');
                return;
            }

            // Unselect the curently selected tab
            newTabs[state.selected].active = false;

            // set active to true so the newly added tab will be active
            action.payload.active = true;

            // set time + millisecondes as id of the tab
            action.payload.id = datum.getTime() + '' + datum.getMilliseconds();

            let addTab = true;

            Object.keys(newTabs).map(key => {
                let entity = newTabs[key].entity;
                let name = newTabs[key].name;
                if (entity.id !== null && entity.id === action.payload.entity.id && name === action.payload.name) {
                    newTabs[key].active = true;
                    action.payload.id = key;
                    addTab = false;
                }
                return key;
            });

            if (addTab) {
                // add payload to state
                newTabs[action.payload.id] = action.payload;
            }

            return {
                ...state,
                byId: newTabs,
                allIds: Object.keys(newTabs),
                selected: action.payload.id
            };
        case tabs.REMOVE_TAB:
            // let selected = action.payload;
            let selected = state.selected;

            if (state.byId[action.payload].active) {
                const obj = Object.keys(state.byId);

                // check for existing tab higher in array
                if (typeof state.allIds[obj.indexOf(action.payload) + 1] !== "undefined") {
                    state.byId[state.allIds[obj.indexOf(action.payload) + 1]].active = true;
                    selected = state.allIds[obj.indexOf(action.payload) + 1];
                } else {
                    state.byId[state.allIds[obj.indexOf(action.payload) - 1]].active = true;
                    selected = state.allIds[obj.indexOf(action.payload) - 1];
                }
            }

            // remove "closed" tab in state
            delete state.byId[action.payload];

            return {
                ...state,
                allIds: state.allIds.filter((index) => index !== action.payload),
                selected: selected,
            };
        case tabs.SELECT_TAB:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload]: {
                        ...state.byId[action.payload],
                        active: true,
                    }
                },
                selected: action.payload,
            };
        case tabs.UNSELECT_TAB:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload]: {
                        ...state.byId[action.payload],
                        active: false,
                    }
                },
                selected: null,
            };
        case tabs.RESET_ENTITY:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload]: {
                        ...state.byId[action.payload],
                        entity: {}
                    }
                }
            };
        case tabs.UPDATE_TAB_ENTITY_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.tabId]: {
                        ...state.byId[action.payload.tabId],
                        entity: action.payload.entity
                    }
                }
            };
        case tabs.SET_TAB_REMOVABLE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.tabId]: {
                        ...state.byId[action.payload.tabId],
                        isRemovable: action.payload.isRemovable
                    }
                }
            };
        default:
            return state;
    }
}
import { actionTypes } from "../constants/actionTypes";

const { filters } = actionTypes;

const initialState = {
    personalFilters: [],
    byId: {},
    activeIds: [],
    allIds: [],
};

export default (state = initialState, action) => {
    switch(action.type) {
        case filters.GET_PERSONAL_FILTERS_SUCCESS:
            let byId = { ...state.byId },
                activeIds = { ...state.activeIds };

            action.payload.map(item => {
                byId[item.id] = item;

                if (item.active) activeIds[item.id] = item;

                return item
            });

            return {
                ...state,
                personalFilters: action.payload,
                byId: byId,
                activeIds: Object.keys(activeIds),
                allIds: Object.keys(byId),
            };
        case filters.UPDATE_PERSONAL_FILTER_SUCCESS:
            let personalFilters = state.personalFilters;
            personalFilters[action.payload.index][action.payload.name] = action.payload.data;
            return {
                ...state,
                personalFilters,
            };
        default:
            return state;
    }
}
import { actionTypes } from "../constants/actionTypes";
import { normalize, schema } from 'normalizr';
import * as moment from 'moment';

const { tickets } = actionTypes;

const engineer = new schema.Entity('engineers', {}, { idAttribute: 'id' });
const action = new schema.Entity('actions', {}, {idAttribute: 'id'});
const contact = new schema.Entity('contacts', {}, {idAttribute: 'id'});
const customer = new schema.Entity('customers', {
    contacts: [contact]
}, {idAttribute: 'id'});
const audit = new schema.Entity('audits', {}, {idAttribute: 'id'});
const tasks = new schema.Entity('tasks', {}, {idAttribute: 'id'});
const ticketTypes = new schema.Entity('ticketTypes', {}, {idAttribute: 'id'});
const categories = new schema.Entity('categories', {}, {idAttribute: 'id'});
const subcategories = new schema.Entity('subcategories', {}, {idAttribute: 'id'});
const incomingKinds = new schema.Entity('incomingKinds', {}, {idAttribute: 'id'});
const messageKinds = new schema.Entity('messageKinds', {}, {idAttribute: 'id'});
const statusses = new schema.Entity('statusses', {}, {idAttribute: 'id'});
const priorities = new schema.Entity('priorities', {}, {idAttribute: 'id'});
const groups = new schema.Entity('groups', {}, {idAttribute: 'id'});

const ticketSchema = new schema.Entity('tickets', {
    engineer: engineer,
    customer: customer,
    actions: [ action ],
    audit: [ audit ],
    tasks: [ tasks ],
    ticketType: ticketTypes,
    category: categories,
    subCategory: subcategories,
    incomingKind: incomingKinds,
    messageKind: messageKinds,
    status: statusses,
    priority: priorities,
    group: groups,
    customerDetails: {
        contactHistory: [],
        customerHistory: [],
        dossiers: {}
    }
},{ idAttribute: 'id' });

const emptyTicketObject = {
    id: 0,
    description: '',
    longDescription: '',
    internalNote: '',
    hourlyRate: 0,
    incomingKind: {},
    messageKind: {},
    category: {},
    subCategory: {},
    priority: {},
    date: moment().format('DD-MM-YYYY HH:mm:ss'),
    dueDate: '',
    group: {},
    status: {value: 1, label: 'In behandeling'},
    dontMail: false,
    invoiced: false,
    totalTime: '00:00',
    maxTime: '',
    fixedAmount: '',
    email: '',
    subGroup: {},
    ticketType: {},
    engineer: {value: Number(localStorage.getItem('user')), label: localStorage.getItem('username')},
    engineers: [],
    customer: {value: null, label: null},
    contact: {},
    actions: [],
    hardware: [],
    audit: [],
    tasks: [],
    knowledge: {},
    template: {},
    userGroup: {value: localStorage.getItem('usergroup'), label: ''},
    changed: moment().format('DD-MM-YYYY HH:mm:ss'),
    otherUser: false,
    customerDetails: {
        contactHistory: [],
        customerHistory: [],
        dossiers: {},
    }
};

const initialState = {
    tickets: normalize([], [ticketSchema]),
    emptyTicket: emptyTicketObject,
    ticketsTodo: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case tickets.POST_TICKETS_SUCCESS:
            return {
                ...state,

            };
        case tickets.GET_TICKETS_SUCCESS:
            return {
                ...state,
                tickets: normalize(action.payload.data, [ ticketSchema ]),
                [action.payload.name]: action.payload.data.map(ticket => {
                    ticket.date = moment(ticket.date).format('DD-MM-YYYY HH:mm:ss');
                    ticket.changed = moment(ticket.changed).format('DD-MM-YYYY HH:mm:ss');
                    ticket.audit.map((audit, key) => {
                        ticket.audit[key].date = moment(audit.date).format('DD-MM-YYYY HH:mm:ss');
                        return audit;
                    });
                    ticket.actions.map((ticketAction, key) => {
                        ticket.actions[key].date = moment(ticketAction.date).format('DD-MM-YYYY');
                        return ticketAction;
                    });
                    ticket.hardware.map((hardware, key) => {
                        ticket.hardware[key].date = moment(hardware.date).format('DD-MM-YYYY');
                        return hardware;
                    });
                    ticket.hourlyRate = String(ticket.hourlyRate);
                    return ticket
                }),
            };
        case tickets.GET_TICKETS_FAILED:
            return {...state};
        case tickets.RESET_TICKET_FORM:
            return {
                ...state,
                ticket: initialState.ticket,
            };
        case tickets.GET_TODOS_SUCCESS:
            return {
                ...state,
                ticketsTodo: action.payload
            };
        case tickets.GET_TODOS_FAILED:
            return {...state};
        default:
            return state;
    }
}
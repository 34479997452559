import { actionTypes } from "../constants/actionTypes";
import { apiRoutes } from "../constants/apiRoutes";
import AuthenticationService from '../services/authenticationService';
import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } = actionTypes.authentication;

const loginCheck = payload => {
    return async dispatch => {
        try {
            let requestData = await ApiService.performRequest(RequestType.POST, apiRoutes.authentication.getToken, payload);

            const { token, user } = requestData;

            await AuthenticationService.setToken(token);
            await AuthenticationService.setUser(user.id);
            await AuthenticationService.setUsername(user);
            await AuthenticationService.setUsergroup(user);

            dispatch({ type: LOGIN_SUCCESS })
        } catch(error) {
            dispatch({ type: LOGIN_FAILED });
        }
    }
};

const logout = () => {
    AuthenticationService.logout();

    return {
        type: LOGOUT,
    }
};

export default {
    loginCheck,
    logout
}
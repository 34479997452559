import React from 'react';
import Modal from "react-bootstrap/Modal";
import ItsBtn from "../../Components/Buttons/ItsBtn";
import Container from "react-bootstrap/Container";
import {toast} from "react-toastify";

class OtherUserModal extends React.Component {
    handleSubmit = () => {
        const { mainTicket } = this.state;
        const { actions, data, handleClose } = this.props;

        //Todo: get the selected tickets
        let combinedTicket = {...mainTicket};

        data.map(ticket => {
            if (ticket.id !== combinedTicket.id) {
                combinedTicket = {
                    ...combinedTicket,
                    actions: [...combinedTicket.actions, ...ticket.actions.map(action => {action.ticketId = combinedTicket.id; return action})],
                    hardware: [...combinedTicket.hardware, ...ticket.hardware.map(hardware => {hardware.ticketId = combinedTicket.id; return hardware})],
                    audit: [...combinedTicket.audit, ...ticket.audit.map(auditLine => {auditLine.ticketId = combinedTicket.id; return auditLine})],
                    tasks: [...combinedTicket.tasks, ...ticket.tasks.map(task => {task.ticketId = combinedTicket.id; return task})],
                };
            }
            return ticket;
        });

        // Recalculate the tickettime
        combinedTicket = this.recalculateTicketTotalTime(combinedTicket);

        //Update the ticket
        actions.ticketActions.putTicket(combinedTicket).then(res => {
            if (res.success) {
                toast.success('Tickets succesvol gecombineerd', {position: 'bottom-right'});
                data.map(ticket => {
                    if (ticket.id !== res.message.id) {
                        actions.ticketActions.deleteTicket(ticket);
                    }
                });
                actions.updateTickets();
                handleClose();
            } else {
                toast.error('Tickets konden niet worden gecombineerd', {position: 'bottom-right'});
            }
        });
    };

    render() {
        const { show, handleClose } = this.props;

        return (
            <>
                <Modal show={show} onHide={() => handleClose()} size={'lg'} >

                    <Modal.Header closeButton>
                        <Modal.Title>Meldingen op jouw naam gezet, door anderen</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container >
                            {this.props.children}
                        </Container>
                    </Modal.Body>

                    <Modal.Footer>
                        {/*<ItsBtn onClick={() => this.handleSubmit()} text={'Opslaan'} />*/}
                        <ItsBtn onClick={() => handleClose()} text={'Sluiten'} />
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default OtherUserModal;
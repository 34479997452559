import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import userActions from "../../actions/userActions";
import tabActions from "../../actions/tabActions";
import SideMenu from "./SideMenu";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import {FaTimes} from "react-icons/fa";
import ticketActions from "../../actions/ticketActions";
import engineerActions from "../../actions/engineerActions";
import customerActions from "../../actions/customerActions";
import listActions from "../../actions/listActions";
import hourActions from "../../actions/hourActions";
import groupActions from "../../actions/groupActions";
import ticketTypeActions from "../../actions/ticketTypeActions";
import authenticationActions from "../../actions/authenticationActions";
import Button from "react-bootstrap/Button";
import backgroundLogo from "../../../src/assets/android-chrome-512x512.png";
import PhoneSystem from "../../Components/PhoneSystem";
import socketIoClient from "socket.io-client";

const TabTitle = (props) => {
    return (
        <div style={{position: 'relative', overflow: 'hidden', width: '100%', height: '100%'}}>
            <span className={'tab-title'}>{props.tab.entity.id !== null ? '#' + props.tab.entity.id : props.tab.name}</span>
            {typeof props.tab.entity.description !== 'undefined' ? <span style={{lineHeight: '13px', fontSize: '10px', position: 'absolute', top: '20px', left: '30px'}}>{props.tab.entity.description}</span> : '' }
            {typeof props.tab.entity.note !== 'undefined' ? <span style={{lineHeight: '13px', fontSize: '10px', position: 'absolute', top: '20px', left: '30px'}}>{props.tab.entity.note}</span> : '' }
        </div>
    )
};

class Dashboard extends React.PureComponent {

    getLoggedInUser() {
        const userid = localStorage.getItem('user');
        this.props.userActions.getLoggedInUser(userid);
    }

    componentDidMount() {
        const { groupActions, userActions, ticketTypeActions } = this.props;
        this.getLoggedInUser();

        // Todo: Nakijken of dit nog nodig is
        groupActions.getGroups();
        userActions.getUsers();
        ticketTypeActions.getTicketTypes();
    }

    removeTab = idx => {
        const { tabActions, tabs } = this.props;
        if (!tabs.byId[idx].isRemovable) {
            if (window.confirm('Weet je zeker dat je dit tabblad met melding wilt sluiten?')) {
                tabActions.removeTab(idx);
            }
        } else {
            tabActions.removeTab(idx);
        }

    };

    selectTab = idx => {
        const { tabActions, history, tabs } = this.props;

        tabActions.unselectTab(tabs.selected);
        tabActions.selectTab(idx);

        history.push({ pathname: tabs.byId[idx].path })
    };

    render() {
        const { tabs, authenticationActions } = this.props;

        return (
            <div className="App">
                <SideMenu routes={tabs.routes} openModal={this.openModal} />

                <Tab.Container defaultActiveKey="first" fluid={true}>
                    <Nav bg={'dark'} variant="tabs" className="custom-nav-tabs">

                        {tabs.allIds.map(idx => {
                            const tab = tabs.byId[idx];

                            return (
                                <Nav.Item key={idx} className={idx !== "0" ? '' : 'home-tab'}>
                                    <Nav.Link
                                        eventKey={idx}
                                        onSelect={ this.selectTab }
                                        active={tab.active}
                                    >
                                        {idx !== "0" ?
                                            <div style={{width: '100%', height: '100%'}}>
                                                {tab.icon}
                                                <TabTitle tab={tab} />
                                            </div>
                                            : tab.icon
                                        }

                                    </Nav.Link>
                                    {idx !== "0" ?
                                        <FaTimes onClick={() => this.removeTab(idx)} className={'close-tab-icon'} />
                                        : (null)
                                    }
                                </Nav.Item>
                            )
                        })}
                    </Nav>

                    <Tab.Content className="custom-content-tabs">
                        {tabs.allIds.map(idx => {
                            const tab = tabs.byId[idx];

                            return (
                                <Tab.Pane index={idx} eventKey={idx} active={tab.active} key={idx}>
                                    <tab.component {...tab} identifier={tab.id} />
                                </Tab.Pane>
                            )
                        })}

                        <div className={'backgroundLogo'}>
                            <img alt="MADDI" src={backgroundLogo} className="d-inline-block align-top backgroundImage"/>
                        </div>
                    </Tab.Content>

                    <div style={{position: 'absolute', right: '10px', top: '15px'}}>
                        <Button
                            onClick={() => authenticationActions.logout()}
                        >
                            Uitloggen
                        </Button>
                    </div>

                    {/*<OtherUser otherUserTickets={loggedInUser.ticketsSetByOtherUser}/>*/}

                </Tab.Container>

                <ToastContainer />

                <PhoneSystem io={socketIoClient('https://partners.itsynergy.nl:3443')}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabs: state.tabs,
        loggedInUser: state.users.loggedInUser
    };
};

const mapDispatchToProps = dispatch => {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        tabActions: bindActionCreators(tabActions, dispatch),
        ticketActions: bindActionCreators(ticketActions, dispatch),
        engineerActions: bindActionCreators(engineerActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
        listActions: bindActionCreators(listActions, dispatch),
        hourActions: bindActionCreators(hourActions, dispatch),
        groupActions: bindActionCreators(groupActions, dispatch),
        ticketTypeActions: bindActionCreators(ticketTypeActions, dispatch),
        authenticationActions: bindActionCreators(authenticationActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
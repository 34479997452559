import { combineReducers } from 'redux';
import authentication from './authenticationReducer';
import users from './userReducer';
import ticket from './ticketReducer';
import customers from './customerReducer';
import contact from './contactReducer';
import optionsList from './listReducer';
import hours from './hourReducer';
import dashboard from './dashboardReducer';
import tabs from './tabReducer';
import phoneNote from './phoneNoteReducer';
import group from './groupReducer';
import filter from './filterReducer';
import ticketType from './ticketTypeReducer';

export default combineReducers({
    authentication,
    users,
    ticket,
    customers,
    contact,
    optionsList,
    hours,
    tabs,
    phoneNote,
    dashboard,
    group,
    filter,
    ticketType
});
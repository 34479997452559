import { actionTypes } from "../constants/actionTypes";

const { contact } = actionTypes;

const emptyContactObject = {
    id: 0,
    customer: {},
    name: '',
    firstName: '',
    lastName: '',
    function: '',
    userName: '',
    phoneNumber: '',
    email: '',
    remark: '',
    adid: '',
    officeid: '',
    mailContact: '',
    phoneNumbers: []
};

const initialState = {
    emptyContact: emptyContactObject
};

export default (state = initialState, action) => {
    switch(action.type) {
        case contact.CONTACT_SELECTED:
            return {
                ...state,
                selected: action.payload
            };
        default:
            return state;
    }
}
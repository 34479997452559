import { actionTypes } from "../constants/actionTypes";

const { optionsList } = actionTypes;


const initialState = {
    engineerList: [],
    customerList: [],
    contactByCustomerList: [],
    groupList: [],
    categoryList: [],
    subcategoryList: [],
    incomingKindList: [],
    messageKindList: [],
    statusList: [],
    productList: [],
    priorityList: [],
    ticketTypeList: [],
    knowledgeList: [],
    templateList: [],
    ledgerList: [],
    subGroupList: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case optionsList.GET_TICKETOPTIONLISTS_SUCCESS:
            let ticketLists = {};
            let list = Object.keys(action.payload);

            list.map(listName => {
                let lists = action.payload[listName];
                let listNaam = listName;

                lists.map((item, idx) => {
                    const { userGroupId } = item;
                    let itemKeys = Object.keys(item);

                    if (typeof ticketLists[userGroupId] === 'undefined') {
                        ticketLists = {...ticketLists, [userGroupId]: {}};
                    }

                    if (typeof ticketLists[userGroupId][listNaam] === 'undefined') {
                        ticketLists[userGroupId] = {
                            ...ticketLists[userGroupId],
                            [listNaam]: []
                        };
                    }

                    if (listNaam === 'subcategoryList') {
                        if (typeof item.parentId === "undefined") {
                            ticketLists[userGroupId][listNaam].push({ label: item[itemKeys[1]], options: [] });
                            lists[listNaam].map(cat => {
                                if (cat.parentId === item.id) {
                                    ticketLists[userGroupId][listNaam].options.push({ label: cat.name, value: cat.id });
                                }

                            });
                        }
                    } else {
                        ticketLists[userGroupId][listNaam].push({ value: item.id, label: item[itemKeys[1]] });
                    }

                    return item;
                });

                return listName;
            });


            return {
                ...state,
                ...ticketLists,
            };
        case optionsList.GET_CUSTOMERS_LIST_SUCCESS:
            return {
                ...state,
                customerList: action.payload.map(item => ({value: item.id, label: item.name + " | " + item.userGroup.name})),
            };
        case optionsList.GET_CONTACT_BY_CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                contactByCustomerList: action.payload.map(item => ({value: item.id, label: item.name})),
            };
        case optionsList.GET_ENGINEERS_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    engineerList: action.payload.data.map(item => ({value: item.id, label: item.firstName + ' ' + item.lastName})),
                }
            };
        case optionsList.GET_GROUP_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    groupList: action.payload.data.map(item => ({value: item.id, label: item.type})),
                }
            };
        case optionsList.GET_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    categoryList: action.payload.data.map(item => ({value: item.id, label: item.name})),
                }
            };
        case optionsList.GET_SUBCATEGORY_LIST_SUCCESS:
            let categoryList = [];
            action.payload.data.map((category, index) => {
                if (typeof category.parentId === "undefined") {
                    categoryList.push({ label: category.name, value: category.id, options: [] });
                    action.payload.data.map(cat => {
                        if (typeof cat.parentId !== "undefined") {
                            if (cat.parentId === category.id) {
                                categoryList[index].options.push({label: cat.name, value: cat.id});
                            }
                        }
                    });
                }
            });

            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    subcategoryList: categoryList.map(category => {
                        if (category.options.length === 0) {
                            category = {label: category.label, value: category.value}
                        }
                        return category;
                    }),
                }
            };
        case optionsList.GET_INCOMING_KIND_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    incomingKindList: action.payload.data.map(item => ({value: item.id, label: item.name})),
                }
            };
        case optionsList.GET_MESSAGE_KIND_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    messageKindList: action.payload.data.map(item => ({value: item.id, label: item.name})),
                }
            };
        case optionsList.GET_KNOWLEDGE_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    knowledgeList: action.payload.data.map(item => ({value: item.id, label: item.title})),
                }
            };
        case optionsList.GET_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    templateList: action.payload.data.map(item => ({value: item.id, label: item.templateName})),
                }
            };
        case optionsList.GET_STATUS_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    statusList: action.payload.data.map(item => ({value: item.id, label: item.status})),
                }
            };
        case optionsList.GET_PRIORITY_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    priorityList: action.payload.data.map(item => ({value: item.id, label: item.name})),
                }
            };
        case optionsList.GET_TICKETTYPE_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    ticketTypeList: action.payload.data.map(item => ({value: item.id, label: item.name})),
                }
            };
        case optionsList.GET_LEDGERS_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    ledgerList: action.payload.data.map(item => ({value: item.id, label: item.name})),
                }
            };
        case optionsList.GET_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    productList: action.payload.data.map(item => ({value: item.id, label: item.name})),
                }
            };
        case optionsList.GET_SUBGROUP_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    subGroupList: action.payload.data.map(item => ({value: item.id, label: item.name})),
                }
            };
        case optionsList.GET_SUPPLIER_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.userGroupId]: {
                    ...state[action.payload.userGroupId],
                    supplierList: action.payload.data.map(item => ({value: item.id, label: item.name + " | " + item.userGroup.name})),
                }
            };
        default:
            return state;
    }
}
import { actionTypes } from "../constants/actionTypes";
import { apiRoutes } from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";

const postTicket = (data) => {
    const postObject = convertDataForBackend(data);

    return async (dispatch) => {
        try {
            let url = apiRoutes.tickets.post;
            return await ApiService.performRequest(RequestType.POST, url, postObject);

        } catch (error) {
            dispatch({ type: actionTypes.tickets.POST_TICKETS_FAILED });
        }
    }
};

const putTicket = (data) => {
    const putObject = convertDataForBackend(data);

    return async dispatch => {
        try {
            let url = apiRoutes.tickets.put + "/" + putObject.id;
            return await ApiService.performRequest(RequestType.PATCH, url, putObject);

        } catch (error) {
            dispatch({ type: actionTypes.tickets.POST_TICKETS_FAILED });
        }
    }
};

const deleteTicket = (data) => {
    const deleteObject = convertDataForBackend(data);

    return async dispatch => {
        try {
            let url = apiRoutes.tickets.delete + "/" + deleteObject.id;
            return await ApiService.performRequest(RequestType.DELETE, url, deleteObject);

        } catch (error) {
            dispatch({ type: actionTypes.tickets.DELETE_TICKETS_FAILED });
        }
    }
};

const getTickets = (queryString, name) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.tickets.getTickets + "?" + queryString,
            response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.tickets.GET_TICKETS_SUCCESS,
                payload: {name: name, data: response},
            });

        } catch (error) {
            dispatch({ type: actionTypes.tickets.GET_TICKETS_FAILED });
        }
    }
};

const newTicket = (data) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.tickets.NEW_TICKET,
            payload: data
        });
    }
};

const resetTicket = () => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.tickets.RESET_TICKET_FORM,
        });
    }
};

const selectContact = (data) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.contact.CONTACT_SELECTED,
            payload: data
        });
    }
};

const createTasks = (data) => {
    return async () => {
        try {
            let url = apiRoutes.tickets.createTasks;
            return await ApiService.performRequest(RequestType.POST, url, data);
        } catch {

        }
    }
};

const getTemplate = (data) => {
    return async () => {
        try {
            let url = apiRoutes.tickets.getTemplate;
            return await ApiService.performRequest(RequestType.POST, url, data);
        } catch {

        }
    }
};

const getContactHistory = (contactId) => {
    return async () => {
        try {
            let url = apiRoutes.customers.getContactHistory + '/' + contactId + '?limit=100';
            return await ApiService.performRequest(RequestType.GET, url);
        } catch {
            return 'Failed to return contacthistory';
        }
    }
};

const getCustomerHistory = (customerId) => {
    return async () => {
        try {
            let url = apiRoutes.customers.getCustomerHistory + '/' + customerId + '?limit=100';
            return await ApiService.performRequest(RequestType.GET, url);
        } catch {
            return 'Failed to return contacthistory';
        }
    }
};

const getDossiers = (customerId) => {
    return async () => {
        try {
            let url = apiRoutes.customers.getDossiers + '/' + customerId + '?limit=100';
            return await ApiService.performRequest(RequestType.GET, url)
        } catch {
            return 'Failed to return contacthistory';
        }
    }
};

const getPredefinedData = (customerId) => {
    return async () => {
        try {
            let url = apiRoutes.tickets.getPredefinedData + '/' + customerId;
            return await ApiService.performRequest(RequestType.GET, url);
        } catch {
            return 'Failed to return predefined data...';
        }
    }
};

const getTicketsTodo = (userId, userGroupId) => {
    return async (dispatch) => {

        try {
            let url = apiRoutes.tickets.getTicketsTodo + "?engineer_id=" + userId + "&user_group_id=" + userGroupId,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.tickets.GET_TODOS_SUCCESS,
                payload: response
            });
        } catch (error) {
            dispatch({type: actionTypes.tickets.GET_TODOS_FAILED})
        }
    }
};

const convertDataForBackend = (data) => {
    let returnObject = {};
    Object.keys(data).map((key) => {
        let input = data[key];
        if (!input && key === 'id') {
            // Dont do anithing as the id is null. Then dont send any id to the backend, just leave it out
        } else if (typeof input.value !== 'undefined' && typeof input.value !== 'object') {
            returnObject = {
                ...returnObject,
                [key]: input.value
            };
        } else if (typeof input === 'object') {
            if (Array.isArray(input)) {
                let arrayOfObjects = [];
                input.map((value) => {
                    let arrayObject = {};
                    Object.keys(value).map((objectKey) => {
                        let objectInput = value[objectKey];
                        if (objectKey === 'value' || objectKey === 'label') {
                            if (objectKey === 'value') {
                                arrayOfObjects.push({engineer: objectInput});
                            }
                        } else if (typeof objectInput.value !== 'undefined') {
                            arrayObject = {
                                ...arrayObject,
                                [objectKey]: objectInput.value
                            };
                        } else if (typeof objectInput.id !== 'undefined') {
                            arrayObject = {
                                ...arrayObject,
                                [objectKey]: objectInput.id
                            };
                        } else if ( typeof objectInput !== 'object') {
                            arrayObject = {
                                ...arrayObject,
                                [objectKey]: objectInput
                            };
                        }
                        return objectKey;
                    });

                    if (Object.keys(arrayObject).length > 0) {
                        arrayOfObjects.push(arrayObject);
                    }

                    return value;
                });

                returnObject = {
                    ...returnObject,
                    [key]: arrayOfObjects
                };
            }
        } else {
            returnObject = {
                ...returnObject,
                [key]: input
            };
        }

        return input;
    });

    return returnObject;
};

export default {
    postTicket,
    putTicket,
    deleteTicket,
    getTickets,
    selectContact,
    newTicket,
    resetTicket,
    createTasks,
    getContactHistory,
    getCustomerHistory,
    getDossiers,
    getPredefinedData,
    getTicketsTodo,
    getTemplate
}
import { actionTypes } from "../constants/actionTypes";
import { apiRoutes } from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";

const getEngineers = (userGroupId) => {
    return async dispatch => {
        try {
            let url = apiRoutes.engineers.getEngineers + "?user_group_id=" + userGroupId;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(_getEngineersSuccess(response))

        } catch (error) {
            dispatch({ type: actionTypes.engineers.getEngineersFailed });
        }
    }
};

const getList = () => {
    return async dispatch => {
        try {
            let url = apiRoutes.engineers.getList;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(_getListSuccess(response))
        } catch (error) {
            dispatch({ type: actionTypes.engineers.GET_ENGINEERS_LIST_FAILED })
        }
    }
};


const _getEngineersSuccess = (data) => {
    return {
        type: actionTypes.engineers.getEngineersSuccess,
        payload: data,
    };
};

const _getListSuccess = data => {
    return {
        type: actionTypes.engineers.GET_ENGINEERS_LIST_SUCCESS,
        payload: data,
    }
};

export default {
    getEngineers,
    getList,
}
const apiRoutes = {
    rootUrl: process.env.REACT_APP_API_URL || 'https://192.168.33.10/api/',

    authentication: {
        login: '',
        logout:'',
        getToken: 'user/login',
    },

    tickets: {
        testPost: 'test',
        testPatch: 'test',
        post: 'ticket/save',
        put: 'ticket/edit',
        delete: 'ticket/delete',
        getTickets: 'ticket',
        getTicket: 'ticket/128306',
        createTasks: 'ticket/createtasks',
        getTemplate: 'ticket/template',
        getPredefinedData: 'ticket/predefineddata',
        getTicketsTodo: 'ticket/ticketstodo'
    },

    ticketType: {
        getList: 'tickettype/tickettypelist',
        getTicketTypes: "tickettype",
    },

    users: {
        getUsers: 'user/',
        getUser: 'user/',
    },

    customers: {
        getList: 'customer/customerlist',
        getCustomers: 'customer',
        getCustomer: 'customer/',
        getContactHistory: 'customer/contacthistory',
        getCustomerHistory: 'customer/customerhistory',
        getDossiers: 'customer/dossiers'
    },

    contacts: {
        post: 'contact/save',
        getContact: 'contact/'
    },

    engineers: {
        getEngineers: 'engineer',
        getList: 'engineer/engineerlist',
    },

    optionsList: {
        getTicketOptionLists: 'option/ticketoptions',
        getEngineerList: 'engineer/engineerlist',
        getCustomerList: 'customer/customerlist',
        getContactByCustomerList: 'contact/contactlist',
        getCategoryList: 'ticketcategory/categorylist',
        getSubcategoryList: 'ticketsubcategory/subcategorylist',
        getIncomingKindList: 'ticketincomingkind/incominglist',
        getMessageKindList: 'ticketmessagekind/messagelist',
        getStatusList: 'status/statuslist',
        getKnowledgeList: 'knowledge/knowledgelist',
        getTemplateList: 'template/templatelist',
        getGroupList: 'group/grouplist',
        getLedgerList: 'ledger/ledgerlist',
        getTicketTypeList: 'tickettype/tickettypelist',
        getPriorityList: 'ticketpriority/ticketprioritylist',
        getProductList: 'product/productlist',
        getSubGroupList: 'subgroup/subgrouplist'
    },

    knowledge: {
        getKnowledge: 'knowledge/103',
        postKnowledge: 'knowledge/save',
    },

    hours: {
        getDailyHours: 'hours/dailyhours',
    },
    phoneNotes: {
        getPhoneNotes: 'phonenote',
        getPhoneNote: 'phonenote/',
        openPhoneNotes: 'phonenote',
        getPhoneNotesByCustomer: 'phonenote',
        postPhoneNote: 'phonenote/save',
        putPhoneNote: 'phonenote/edit',
    },
    ticketTasks: {
        getTicketTasks: 'tickettask/'
    },
    groups: {
        getGroups: 'group/',
        getGroup: 'group/'
    },

    products: {
        getProduct: 'product/',
    },

    filters: {
        putFilter: "filter/updatefilter",
        getPersonalFilters: 'filter/personalfilters'
    },

    email : {
        sendEmail: 'email/post',
    }

};

export {
    apiRoutes
}

import { actionTypes } from "../constants/actionTypes";
import { apiRoutes } from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";

const getGroups = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.groups.getGroups;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.groups.GET_GROUPS_SUCCESS,
                payload: response
            })

        } catch (error) {
            dispatch({ type: actionTypes.tickets.POST_TICKETS_FAILED });
        }
    }
};

export default {
    getGroups
};
import { actionTypes } from "../constants/actionTypes";

const initialState = {
    dailyHours: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.hours.GET_DAILY_HOURS_SUCCESS:
            return {
                ...state,
                dailyHours: action.payload
            };
        case actionTypes.hours.GET_DAILY_HOURS_FAILED:
            return {...state};
        default:
            return state;
    }
}
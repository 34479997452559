import axios from 'axios';
import RequestType from '../constants/requestType';
import {apiRoutes} from '../constants/apiRoutes';
import authenticationService from './authenticationService';
import authenticationActions from '../actions/authenticationActions';

import store from '../store';

export default class ApiService {

    static ROOT_URL = apiRoutes.rootUrl;

    static async performRequest(type, url, data = null) {
        let token = authenticationService.getToken();
        let response;

        try{
            switch(type) {
                case RequestType.GET:
                    let req = await axios.get(ApiService.ROOT_URL + url, {headers: {'Authorization' : `Bearer ${token}`}});
                    response =  req;
                    return response.data;
                case RequestType.GETFILE:
                    response = await this.getFileRequest(url);
                    break;
                case RequestType.PUT:
                    response = await this.putRequest(url, data);
                    break;
                case RequestType.DELETE:
                    response = await this.deleteRequest(url);
                    break;
                case RequestType.POST:
                    response = await this.postRequest(url, data);
                    break;
                case RequestType.PATCH:
                    response  = await this.patchRequest(url, data);
                    break;
                default:
                    return null;
            }

            return response.data;
        } catch(e) {
            if(e.response && e.response.status === 401) {
                store.dispatch(authenticationActions.logout());
            }
            throw e;
        }


    }

    static getFileRequest = async (url) => {
        let token = authenticationService.getToken();
        return await axios.get(ApiService.ROOT_URL + url, { responseType:"blob",  headers: {'Authorization' : `Bearer ${token}`}});
    };

    static putRequest = async (url, data) => {
        let token = authenticationService.getToken();
        return await axios.put(ApiService.ROOT_URL + url, data, {headers: {'Authorization' : `Bearer ${token}`}});
    };

    static deleteRequest = async (url) => {
        let token = authenticationService.getToken();
        return await axios.delete(ApiService.ROOT_URL + url, {headers: {'Authorization' : `Bearer ${token}`}});
    };

    static postRequest = async (url, data) => {
        let token = authenticationService.getToken();
        if (data instanceof File) {
            let formData = new FormData();
            formData.append('file', data);
            return await axios.post(ApiService.ROOT_URL + url, formData, {headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'multipart/form-data'
                }});
        }
        else {
            return await axios.post(ApiService.ROOT_URL + url, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
        }
    };

    static patchRequest = async (url, data) => {
        let token = authenticationService.getToken();
        return await axios.patch(ApiService.ROOT_URL + url, data, {headers: {'Authorization' : `Bearer ${token}`}})
    }
}

import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from "redux";
import store from './store';
import authenticationActions from './actions/authenticationActions';

import Dashboard from "./containers/Layout/Dashboard";
import Login from "./containers/Authentication/Login";

class AppRouter extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { authentication } = this.props;

        return (
            <BrowserRouter>
                {authentication.isLoggedIn ?

                  <Switch>
                      <Route exact path="/*" name="Home" component={Dashboard} />
                  </Switch> :

                  <Switch>
                      <Route path="/" name="Login" component={Login} />
                  </Switch>
                }
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        authenticationActions: bindActionCreators(authenticationActions, dispatch),
    };
};

const AppRouterContainer = connect(mapStateToProps, mapDispatchToProps)(AppRouter);

const App = () => (
    <Provider store={store}>
        <AppRouterContainer/>
    </Provider>
);

export default App;

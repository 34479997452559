import { actionTypes } from "../constants/actionTypes";
import { apiRoutes } from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";

const { customers } = actionTypes;

const getCustomers = () => {
    return async dispatch => {
        try {
            let url = apiRoutes.customers.getCustomers,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(_getCustomersSuccess(response))
        } catch(error) {
            dispatch({ type: customers.GET_CUSTOMERS_FAILED })
        }
    }
};

const returnCustomer = (customerId) => {
    return async dispatch => {
        try {
            let url = apiRoutes.customers.getCustomer + customerId;
            return await ApiService.performRequest(RequestType.GET, url);
        } catch (error) {
            console.log(error);
        }
    }
};

const getCustomersList = (userGroupId) => {
    return async dispatch => {
        try {
            let url = apiRoutes.customers.getList+ "?user_group_id=" + userGroupId,
                response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(_getListSuccess(response))
        } catch (error) {
            dispatch({ type: customers.GET_CUSTOMERS_LIST_FAILED })
        }
    }
};

const selectCustomer = (data) => {
    return async dispatch => {
        dispatch({
            type: customers.CUSTOMER_SELECTED,
            payload: data
        })
    }
};

const getCustomerLabel = (customerid) => {
    return async() => {
        try {
            let url = apiRoutes.customers.getCustomer,
                response = await ApiService.performRequest(RequestType.GET, url + customerid);

            return response;
        } catch (error) {

        }
    }
};

const getCustomer = (customerid) => {
    return async dispatch => {
        try {
            let url = apiRoutes.customers.getCustomer,
                response = await ApiService.performRequest(RequestType.GET, url + customerid);

            dispatch(_getCustomersSuccess(response))

        } catch(error) {
            dispatch({ type: customers.GET_CUSTOMERS_FAILED })
        }
    }
};

const _getCustomersSuccess = data => {
    return {
        type: actionTypes.customers.GET_CUSTOMERS_SUCCESS,
        payload: data,
    }
};

const _getListSuccess = data => {
    return {
        type: actionTypes.customers.GET_CUSTOMERS_LIST_SUCCESS,
        payload: data,
    }
};

export default {
    getCustomers,
    getCustomer,
    getCustomersList,
    selectCustomer,
    getCustomerLabel,
    returnCustomer
}